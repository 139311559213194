import React from 'react'
import { Box, Collapse, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material';

  // // PASTE THIS INTO THE PARENT COMPONENT
  // // ALERTS
  // const [goTime, setGoTime] = useState(false);
  // const [notifyContent, setNotifyContent] = useState();
  // const handleNotification = (content) => {
  //   setNotifyContent(content);
  //   setGoTime(true);
  //   setTimeout(() => {setGoTime(false)}, 5000);
  // };
  // const handleStop = () => {
  //   setGoTime(false);
  // };


const AlertsTemplate = (props) => {
  const { content, color, text, goTime, handleStop } = props;
  // console.log(goTime);
  return (
    <Box
      className="AlertsTemplate one"
      sx={{
        position:'fixed',
        // width:goTime ? '100%' : '0%',
        // justifyContent:'center',
        top:20,
        // height:'100vh',
        // left:'50%',
        right:0, //'5%',
        zIndex: 3000
      }}
      xs={12}
    >
      <Collapse
        orientation="horizontal"
        in={goTime}
      >
        <Grid
          item
          className="AlertsTemplate two"
          container
          sx={{
            justifyContent:'center',
            mr:15
          }}
          xs={12}
        >
          <Grid
            className="AlertsTemplate three"
            item
          >
            <Box
              className="AlertsTemplate four"
              component={Stack}
              direction="row"
              alignItems="center"
              sx={{
                // backgroundColor:color,
                background:color,
                borderRadius:2,
                py:1,
                px:3
              }}
            >
              <Typography sx={{textAlign:'center', fontWeight:400, color:text ? text : '#fff'}} noWrap>{content}</Typography>
              <IconButton
                onClick={handleStop}
                sx={{
                  ml:1
                }}
              >
                <Close sx={{color:text ? text : '#fff'}} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default AlertsTemplate