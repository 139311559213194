import { useState, useEffect } from 'react'
import { useAuth } from "../context/authContext";
import { query, collectionGroup, where, onSnapshot, collection, doc , getDocs, orderBy} from "firebase/firestore";
import { db } from "../firebase";

// Organize mutation hooks by this order:
// 1. Collection
// 2. Create / Update (addDoc, setDoc)
// 3. Read (onSnapshot, getDoc)
// 4. Delete (deleteDoc)
// COMMENT FORMAT (copy and paste format below)

// =============================================================
// NAME: getAssignments (do not include 'use' at beginning)
// =============================================================
//
// FUNCTION:
//    Gets assignments
//
// QUERIES: 
//    where(
//      assignmentActive == true
//    )
//    orderBy(
//      assignmentActive 'desc'
//    )
//
// NOTES:
//    No additional notes
//
// =============================================================
// =============================================================


// EXAMPLE HOOK FOR GETTING AND 'LISTENING' TO DATA
export const useGetExample = () => {
  const [examples, setExamples] = useState();
  const authContext = useAuth();
  const getExamples = async () => {
    try {
      const collectionRef = collectionGroup(db, "examples");
      const q = query(collectionRef, where("exampleActive", "==", true));
      onSnapshot(q, (snapshot) => {
        setExamples(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });
    } catch (error) {
      console.log("Error on getting Example docs", error);
    }
  }

  useEffect(() => {
    getExamples();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.account]);

  return examples ? examples : null
}

// =============================================================
// COLLECTION: courses
// =============================================================

// Use this for getting courses with variable queries
export const useGetCourses = (queries) => {
  const [courses, setCourses] = useState();
  const authContext = useAuth();

  const getCourses = async () => {
    try {
      const collectionRef = collection(db, 'courses');

      // Set query to the entire courses collection as a default
      let q = collection(db, 'courses');

      if (queries === 'teacherId') {q = query(collectionRef, where('teacherId', '==', authContext.account.id), orderBy('updatedAt', 'desc'))}
      if (queries === 'title=English') {q = query(collectionRef, where('title', '==', 'English'))}

      onSnapshot(q, (snapshot) => {
        setCourses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })

    } catch (error) {
      console.log('Error getting courses', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getCourses();
    }
  }, [authContext.account])

  return courses ? courses : null

}

// Use this for getting courses that were created by the current user (teacher)
export const useGetCoursesTeacherId = (teacherId) => {
  const [courses, setCourses] = useState();
  const authContext = useAuth();


  const getCourses = async () => {
    try {
      const collectionRef = collection(db, 'courses');

      // Set query to the entire courses collection as a default
      const q = query(collectionRef, where('teacherId', '==', teacherId ? teacherId : authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setCourses(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })

    } catch (error) {
      console.log('Error getting courses', error);
    }
  }

  useEffect(() => {
    getCourses();
  }, [authContext.account])

  return courses ? courses : null

}

// Use this for getting a single course based on the course id (courseId)
export const useGetCourse = (courseId) => {
  const [course, setCourse] = useState();

  const getCourse = async () => {
    try {
      const courseRef = doc(db, 'courses', courseId);

      onSnapshot(courseRef, (doc) => {
        setCourse({...doc.data(), id: doc.id})
      })


    } catch (error) {
      console.log('Error getting single course', error);
    }
  }

  useEffect(() => {
    getCourse();
  }, [courseId])

  return course ? course : null

}





// =============================================================
// COLLECTION: sections
// =============================================================

// Get all sections based on the current user (teacher)
export const useGetSectionsTeacherId = () => {
  const [sections, setSections] = useState();
  const authContext = useAuth();

  const getSections = async () => {
    try {
      const collectionRef = collection(db, 'sections');
      const q = query(collectionRef, where('teacherId', '==', authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setSections(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting sections', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getSections();
    }
  }, [authContext.account])

  return sections ? sections : null

}

// Get all sections based on the current user (teacher)
export const useGetSectionsTeacherCourseId = (courseId) => {
  const [sections, setSections] = useState();
  const authContext = useAuth();

  const getSections = async () => {
    try {
      const collectionRef = collection(db, 'sections');
      const q = query(
        collectionRef,
        where('teacherId', '==', authContext.account.id),
        where('courseIds', 'array-contains', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setSections(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting sections', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getSections();
    }
  }, [authContext.account])

  return sections ? sections : null

}

// Use this for getting a single course based on the course id (courseId)
export const useGetSection = (sectionId) => {
  const [section, setSection] = useState();

  const getSection = async () => {
    try {
      const sectionRef = doc(db, 'sections', sectionId);

      onSnapshot(sectionRef, (doc) => {
        setSection({...doc.data(), id: doc.id})
      })


    } catch (error) {
      console.log('Error getting single section', error);
    }
  }

  useEffect(() => {
    getSection();
  }, [sectionId])

  return section ? section : null

}





// =============================================================
// COLLECTION: quizzes
// =============================================================

// Get quiz based on quiz id (quizId)
export const useGetQuiz = (quizId) => {
  const [quiz, setQuiz] = useState();

  const getQuiz = async () => {
    try {
      const docRef = doc(db, 'quizzes', quizId);

      onSnapshot(docRef, (doc) => {
        setQuiz({...doc.data(), id: doc.id});
      })

    } catch (error) {
      console.log('Error getting quiz', error);
    }
  }

  if (quiz?.createType === 'link') {

  } 


  useEffect(() => {
    if(quizId) {
      getQuiz();
    }
  }, [quizId]);


  // ==================================
  // USE IF QUIZ.CREATETYPE === 'LINK'
  // ==================================

  const [studyset, setStudyset] = useState();

  useEffect(() => {
    if (quiz?.createType === 'link') {
      getStudySet(quiz.studysetId);
    }
  }, [quiz?.studysetId])

  const getStudySet = async (studysetId) => {
    try {
      const studysetRef = doc(db, 'sets', studysetId);

      onSnapshot(studysetRef, (doc) => {
        setQuiz({ ...quiz, cards: doc.data().cards })
      })

    } catch (error) {
      console.log('Error getting single study set', error);
    }
  }






  return quiz ? quiz : null
}

// Get all quizzes based on the current user (teacher)
export const useGetQuizzesTeacherId = (teacherId) => {
  const [quizzes, setQuizzes] = useState();
  const authContext = useAuth();

  const getQuizzes = async () => {
    try {
      const collectionRef = collection(db, 'quizzes');
      const q = query(collectionRef, where('teacherId', '==', teacherId ? teacherId : authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting quizzes', error);
    }
  }

  useEffect(() => {
    if (authContext.account || teacherId) {
      getQuizzes();
    }
  }, [authContext.account, teacherId])

  return quizzes ? quizzes : null

}

// Get all quizzes based on the current user (teacher) and selected class (courseId)
export const useGetQuizzesTeacherClassId = (courseId) => {
  const [quizzes, setQuizzes] = useState();
  const authContext = useAuth();

  const getQuizzes = async () => {
    try {
      const collectionRef = collection(db, 'quizzes');
      const q = query(
        collectionRef,
        where('teacherId', '==', authContext.account.id),
        where('courseIds', 'array-contains', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting quizzes', error);
    }
  }

  useEffect(() => {
    if (authContext.account && courseId) {
      getQuizzes();
    }
  }, [authContext.account, courseId])

  return quizzes ? quizzes : null

}


// Get all quizzes based on the current user (teacher) and selected class (courseId)
export const useGetQuizzesClassId = (courseId) => {
  const [quizzes, setQuizzes] = useState();

  const getQuizzes = async () => {
    try {
      const collectionRef = collection(db, 'quizzes');
      const q = query(
        collectionRef,
        where('courseIds', 'array-contains', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting quizzes', error);
    }
  }

  useEffect(() => {
    if (courseId) {
      getQuizzes();
    }
  }, [courseId])

  return quizzes ? quizzes : null

}



// =============================================================
// COLLECTION: activeQuizzes
// =============================================================


// Use this for getting an active quiz based on the active quiz id (activeQuizId)
export const useGetActiveQuiz = (activeQuizId) => {
  const [activeQuiz, setActiveQuiz] = useState();

  const getActiveQuiz = async () => {
    try {
      const activeQuizRef = doc(db, 'activeQuizzes', activeQuizId);

      onSnapshot(activeQuizRef, (doc) => {
        setActiveQuiz({...doc.data(), id: doc.id})
      })


    } catch (error) {
      console.log('Error getting single activeQuiz', error);
    }
  }

  useEffect(() => {
    if (activeQuizId) {
      getActiveQuiz();
    }
  }, [activeQuizId])

  return activeQuiz ? activeQuiz : null
}

// Use this to get all the active activeQuizzes by teacherId
export const useGetActiveQuizzesTeacherId = () => {
  const [activeQuizzes, setActiveQuizzes] = useState();
  const authContext = useAuth();

  const getActiveQuizzes = async () => {
    try {
      const collectionRef = collection(db, 'activeQuizzes');
      const q = query(collectionRef, where('teacherId', '==', authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setActiveQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting activeQuizzes', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getActiveQuizzes();
    }
  }, [authContext.account])

  return activeQuizzes ? activeQuizzes : null

}

// Use this to get all the active activeQuizzes by quizId
export const useGetActiveQuizzesByTeacherQuizId = (quizId) => {
  const [activeQuizzes, setActiveQuizzes] = useState();
  const authContext = useAuth();

  const getActiveQuizzes = async () => {
    try {
      const collectionRef = collection(db, 'activeQuizzes');
      const q = query(
        collectionRef,
        where('teacherId', '==', authContext.account.id),
        where('quizId', '==', quizId),
        orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setActiveQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting activeQuizzes', error);
    }
  }

  useEffect(() => {
    if (authContext.account && quizId) {
      getActiveQuizzes();
    }
  }, [authContext.account, quizId])

  return activeQuizzes ? activeQuizzes : null

}

// export const useGetAllActiveQuizByTeacherId = (teacherId) => {
//   const [activeQuizzes, setActiveQuizzes] = useState();

//   const getActiveQuizzes = async () => {
//     try {
//       // const activeQuizzesCol = db.collection('activeQuizzes').where(`teacherId`, '!=', teacherId).get();

//       //https://firebase.google.com/docs/firestore/query-data/queries
//       const activeQuizzesRef = collection(db, "activeQuizzes");

//       // Create a query against the collection.
//       const q = query(activeQuizzesRef, where("activeTeacherId", "==", teacherId));
//       console.log("query", q);

//       const querySnapshot = await getDocs(q); //querySnapshot is an object list of activeQuiz objects
//       //convert it into an array of activeQuiz objects
//       let queryArr = [];
//       querySnapshot.forEach((doc) => {
//         // doc.data() is never undefined for query doc snapshots
//         console.log(doc.id, " => ", doc.data()); //each doc.data() is an activeQuiz object
//       });
      
//       setActiveQuizzes(queryArr);

//     } catch (error) {
//       console.log('Error getting single activeQuiz', error);
//     }
//   }

//   useEffect(() => {
//     if (teacherId) {
//       getActiveQuizzes();
//     }
//   }, [teacherId])

//   return activeQuizzes ? activeQuizzes : null
// }






// =============================================================
// COLLECTION: teams (subCollection of activeQuizzes)
// =============================================================


// Get all teams from an active quiz
export const useGetTeams = (activeQuizId) => {
  const [teams, setTeams] = useState();
  const authContext = useAuth();

  const getTeams = async () => {
    try {
      const collectionRef = collection(db, 'activeQuizzes', activeQuizId, 'teams');
      // const q = query(collectionRef, where('teacherId', '==', authContext.account.id));
      onSnapshot(collectionRef, (snapshot) => {
        setTeams(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting teams', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getTeams();
    }
  }, [authContext.account])

  return teams ? teams : null

}

// Use this for getting a team of an active quiz based on the activeQuizId and teamId
export const useGetTeam = (activeQuizId, teamId) => {
  const [team, setTeam] = useState();

  const getTeam = async () => {
    try {
      const activeQuizRef = doc(db, 'activeQuizzes', activeQuizId, 'teams', teamId);
      onSnapshot(activeQuizRef, (doc) => {
        setTeam({...doc.data(), id: doc.id})
      })

    } catch (error) {
      console.log('Error getting single team', error);
    }
  }

  useEffect(() => {
    if (activeQuizId && teamId) {
      getTeam();
    }
  }, [activeQuizId, teamId])

  return team ? team : null
}



  
  
// Use this for getting an active quiz based on the active quiz invite code (activeQuizInvite)
// export const useGetActiveQuizByInvite = (activeQuizInvite) => {
//   const [activeQuiz, setActiveQuiz] = useState();

//   const getActiveQuiz = async () => {
//     try {
//       const collectionRef = collection(db, 'activeQuizzes');
//       const q = query(collectionRef, where('inviteCode', "==", activeQuizInvite));
//       onSnapshot(q, (snapshot) => {
//         setActiveQuiz(
//           snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
//         );
//       });
//     } catch (error) {
//       console.log('Error getting active quiz', error);
//     }
//   }

//   useEffect(() => {
//     if (activeQuizId) {
//       getActiveQuiz();
//     }
//   }, [activeQuizId]);

//   return activeQuiz ? activeQuiz : null

// }




// =============================================================
// COLLECTION: sets
// =============================================================


// Get all study sets based on current user (teacher)
export const useGetStudySetTeacherId = (teacherId) => {
  const [studysets, setStudysets] = useState();
  const authContext = useAuth();
  const getStudySet = async () => {
    try {
      const collectionRef = collection(db, 'sets');
      const q = query(collectionRef, where('teacherId', '==', teacherId ? teacherId : authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(q, (snapshot) => {
        setStudysets(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting study sets', error);
    }
  }

  useEffect(() => {
    if (authContext.account || teacherId) {
      getStudySet();
    }
  }, [authContext.account, teacherId])

  return studysets ? studysets : null

}

// Get all study sets based on the current user (teacher) and selected class (courseId)
export const useGetStudySetTeacherClassId = (courseId) => {
  const [studysets, setStudysets] = useState();
  const authContext = useAuth();

  const getStudySets = async () => {
    try {
      const collectionRef = collection(db, 'sets');
      const q = query(
        collectionRef,
        where('teacherId', '==', authContext.account.id),
        where('courseIds', 'array-contains', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setStudysets(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting studysets', error);
    }
  }

  useEffect(() => {
    if (authContext.account && courseId) {
      getStudySets();
    }
  }, [authContext.account, courseId])

  return studysets ? studysets : null

}


export const useGetStudySetClassId = (courseId) => {
  const [studysets, setStudysets] = useState();

  const getStudySets = async () => {
    try {
      const collectionRef = collection(db, 'sets');
      const q = query(
        collectionRef,
        where('courseIds', 'array-contains', courseId)
      );
      onSnapshot(q, (snapshot) => {
        setStudysets(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting studysets', error);
    }
  }

  useEffect(() => {
    if (courseId) {
      getStudySets();
    }
  }, [courseId])

  return studysets ? studysets : null

}



// Use this for getting a single study set based on the study set id (studysetId)
export const useGetStudySet = (studysetId, condition) => { //condition is an object
  const [studyset, setStudyset] = useState();

  const getStudySet = async () => {
    try {
      const studysetRef = doc(db, 'sets', studysetId);

      onSnapshot(studysetRef, (doc) => {
        setStudyset({...doc.data(), id: doc.id})
      })


    } catch (error) {
      console.log('Error getting single study set', error);
    }
  }

  useEffect(() => {
    if (studysetId) {
      getStudySet();
    }
  }, [studysetId])

  return studyset ? studyset : null

}




// =============================================================
// COLLECTION: users
// =============================================================


// Get a user (teacher) based on their id
export const useGetTeacherById = (teacherId) => {
  const [teacher, setTeacher] = useState();

  const getTeacher = async () => {
    try {
      const teacherRef = doc(db, 'users', teacherId);
      onSnapshot(teacherRef, (doc) => {
        setTeacher({...doc.data(), id: doc.id})
      })

    } catch (error) {
      console.log('Error getting teacher', error);
    }
  }

  useEffect(() => {
    if (teacherId) {
      getTeacher();
    }
  }, [teacherId])

  return teacher ? teacher : null
}




// =============================================================
// COLLECTION: feedback
// =============================================================


// Get all feedback
export const useGetFeedback = () => {
  const [feedback, setFeedback] = useState();

  const getFeedback = async () => {
    try {
      const collectionRef = collection(db, 'feedback');

      onSnapshot(collectionRef, (snapshot) => {
        setFeedback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feedback', error);
    }
  }

  useEffect(() => {
      getFeedback();
  }, [])

  return feedback ? feedback : null
}

// Get feedback made by current user
export const useGetUsersFeedback = () => {
  const [feedback, setFeedback] = useState();
  const authContext = useAuth();

  const getUsersFeedback = async () => {
    try {
      const collectionRef = collection(db, 'feedback');
      const q = query(
        collectionRef,
        where('userId', '==', authContext.account.id)
      );

      onSnapshot(q, (snapshot) => {
        setFeedback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feedback', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getUsersFeedback();
    }
  }, [authContext.account])

  return feedback ? feedback : null
}





// =============================================================
// COLLECTION: featureRequests
// =============================================================


// Get all reported feature requests
export const useGetFeatureRequests = () => {
  const [featureRequests, setFeatureRequests] = useState();

  const getFeatureRequests = async () => {
    try {
      const collectionRef = collection(db, 'featureRequests');

      onSnapshot(collectionRef, (snapshot) => {
        setFeatureRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getFeatureRequests();
  }, [])

  return featureRequests ? featureRequests : null
}

// Get reported feature requests made by current user
export const useGetUsersFeatureRequests = () => {
  const [featureRequests, setFeatureRequests] = useState();
  const authContext = useAuth();

  const getUsersFeatureRequests = async () => {
    try {
      const collectionRef = collection(db, 'featureRequests');
      const q = query(
        collectionRef,
        where('userId', '==', authContext.account.id)
      );

      onSnapshot(q, (snapshot) => {
        setFeatureRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getUsersFeatureRequests();
    }
  }, [authContext.account])

  return featureRequests ? featureRequests : null
}





// =============================================================
// COLLECTION: bugs
// =============================================================


// Get all reported bugs
export const useGetBugs = () => {
  const [bugs, setBugs] = useState();

  const getBugs = async () => {
    try {
      const collectionRef = collection(db, 'bugs');

      onSnapshot(collectionRef, (snapshot) => {
        setBugs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getBugs();
  }, [])

  return bugs ? bugs : null
}

// Get reported bugs from current user
export const useGetUsersBugs = () => {
  const [bugs, setBugs] = useState();
  const authContext = useAuth();

  const getUsersBugs = async () => {
    try {
      const collectionRef = collection(db, 'bugs');
      const q = query(
        collectionRef,
        where('userId', '==', authContext.account.id)
      );

      onSnapshot(q, (snapshot) => {
        setBugs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getUsersBugs();
    }
  }, [authContext.account])

  return bugs ? bugs : null
}




// =============================================================
// COLLECTION: logNotes
// =============================================================


// Get a user (teacher) based on their id
export const useGetUserLogNotes = () => {
  const [logNotes, setLogNotes] = useState();
  const authContext = useAuth();

  const getUserLogNotes = async () => {
    try {
      const docRef = doc(db, 'logNotes', authContext.account.id);

      onSnapshot(docRef, (doc) => {
        setLogNotes({...doc.data(), id: doc.id});
      })
      
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
    if (authContext.account) {
      getUserLogNotes();
    }
  }, [authContext.account])

  return logNotes ? logNotes : null
}

