import { useTheme } from '@emotion/react';
import { doc, serverTimestamp, setDoc } from '@firebase/firestore';
import { Close, Edit, Feedback, Fullscreen, FullscreenExit, Notes } from '@mui/icons-material';
import { Box, Button, Drawer, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../context/authContext';
import { db } from '../../firebase';
import { useGetUserLogNotes } from '../../hooks/mutations';
import { isObjEqual } from '../../lib/Functions';
import MainWrapper from '../templates/MainWrapper';

const LogNotes = () => {
  const authContext = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {defaultMatches: true})
  const initLogNotes = useGetUserLogNotes();
  const [open, setOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [editId, setEditId] = useState(null);
  
  const path = window.location.pathname;
  let pageType = path === '/' ? 'dashboard' : path.split('/').map((item, index) => index % 2 !== 0 ? ` ${item}` : '').join('');
  let pageTypeField = pageType.replace(/\s/g, '');

  const [logNotes, setLogNotes] = useState()
  console.log('logNotes', logNotes?.[pageTypeField]);
  // console.log('editId', editId);
  // console.log('pageTypeField', pageTypeField);
  // console.log('is equal', isObjEqual(initLogNotes, logNotes));

  // Initialize the logNotes state from initLogNotes
  useEffect(() => {
    if(initLogNotes) {
      setLogNotes(initLogNotes);
      if (!initLogNotes[pageTypeField]) {
        handleNoCurrentCard();
      }
    }
  }, [initLogNotes, pageTypeField])

  // Update page state when a new page is navigated to
  useEffect(() => {
    console.log('path has changed');
    if (!isObjEqual(initLogNotes, logNotes)) {
      AddNote();
    }
    pageType = path === '/' ? 'dashboard' : path.split('/').map((item, index) => index % 2 !== 0 ? ` ${item}` : '').join('');
    pageTypeField = pageType.replace(/\s/g, '');
  }, [path])


  const handleClose = () => {
    setOpen(false);
    console.log(isObjEqual(initLogNotes, logNotes));
    if (!isObjEqual(initLogNotes, logNotes)) {
      AddNote();
    }
  }

  function writeToArray(obj, arrayName, note) {
    // Find the array with the given name
    const array = obj[arrayName];
  
    // Generate a new item with a unique ID and the given note
    const newItem = { id: array.length, note: note };
  
    // Add the new item to the beginning of the array
    array.unshift(newItem);
   
    // Return the updated object
    return obj;
  }
  

  // Update the note when text is entered in the input field
  const handleNoteUpdate = (id, note) => {
    const noteIndex = logNotes[pageTypeField].findIndex((item) => item.id === id);
    // console.log('noteIndex', noteIndex)

    const generateId = () => {
      const str = logNotes[pageTypeField].length.toString();
      return "0".repeat(4 - str.length) + str;
    };
    // console.log('generateId', generateId())
  
    if (!id) {
      // The note doesn't exist, so add it to the array with a new ID
      const newId = generateId();
      setLogNotes({
        ...logNotes, 
          [pageTypeField]: logNotes[pageTypeField].unshift(
            {
              id: newId,
              note: note,
              saved: false,
              createdAt: Date(),
              updatedAt: Date()
            }
          )
        }
      );
    } else {
      // The note already exists, so update it
      const updatedNotes = [...logNotes[pageTypeField]];
      updatedNotes[noteIndex] = {
        ...updatedNotes[noteIndex],
        note: note,
        updatedAt: Date()
      };
      setLogNotes({
        ...logNotes,
        [pageTypeField]: updatedNotes
      });
    }
  };

  const handleDone = (id) => {
    const noteIndex = logNotes[pageTypeField].findIndex((item) => item.id === id);
    // console.log('noteIndex', noteIndex)


    const generateId = () => {
      const str = logNotes[pageTypeField].length.toString();
      return "0".repeat(4 - str.length) + str;
    }

    const newId = generateId();
    // console.log('newId', newId);

    // The note already exists, so update it
    const updatedNotes = [...logNotes[pageTypeField]];
    
    // If the current note has not been saved then save it and add a new note
    if (!updatedNotes[noteIndex].saved) {
      //Update the current note
      updatedNotes[noteIndex] = {
        ...updatedNotes[noteIndex],
        saved:true,
        updatedAt: Date()
      };
  
      // Add a new note
      updatedNotes.unshift({
        id: newId,
        note:'',
        saved:false,
        createdAt: Date(),
        updatedAt: Date()
      });

      setEditId(newId);
    }

    // If the current note has been saved then save it without adding a new note
    else {
      //Update the current note
      updatedNotes[noteIndex] = {
        ...updatedNotes[noteIndex],
        saved:true,
        updatedAt: new Date()
      };

      setEditId(null);

    }

    setLogNotes({
      ...logNotes,
      [pageTypeField]: updatedNotes
    });

    AddNote({...logNotes, [pageTypeField]: updatedNotes})


  }

  // Add a note if it is a new page without notes yet. This is the initial note function.
  const handleNoCurrentCard = () => {
    // Filter for cards where saved is false or doesn't exist
    const currentNote = logNotes?.[pageTypeField]?.filter(x => !x.saved);
    if (!currentNote?.length) {
      setLogNotes(prev => ({
        ...prev,
        
          [pageTypeField]:[
            {
              id:'0000',
              note:''
            }
          ]
        })
      )
    }
  }
  
  const AddNote = async (recentNotes) => {
    try {
      const userRef = doc(db, 'logNotes', authContext.account.id);
      const userPayload = {
        ...logNotes,
        ...recentNotes,
        id: authContext.account.id,
        firstName: authContext.account.firstName || '',
        lastName: authContext.account.lastName || '',
        updatedAt: serverTimestamp(),
        createdAt: initLogNotes ? initLogNotes.createdAt : serverTimestamp()
      }

      await setDoc(userRef, userPayload, {merge:true});

    } catch (error) {
      console.log('Could not add note', error);
    }
  }




  return (
    <>
      <Box
        sx={{
          position:'fixed',
          bottom:20,
          right:isMobile ? 70 : 20,
          transition:'.4s',
          zIndex:300
        }}
      >
        <Box
          onClick={() => setOpen(!open)}
          sx={{
            p:1,
            width: isMobile ? 30 : 50,
            height: isMobile ? 30 : 50,
            borderRadius:3,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            cursor:'pointer',
            background: `linear-gradient(-45deg, ${theme.palette.brandLight.five}, ${theme.palette.brandPastel.six})`,
          }}
        >
          <Notes sx={{fontSize:32, color:'#fff'}} />
        </Box>

          <Drawer
            anchor="bottom"
            open={open}
            variant="persistent"
            onClose={handleClose}
          >
            <Grid
              container
              sx={{
                flexDirection:'column',
                height: fullscreen ? '100vh' : '40vh',
                transition:'.3s'
              }}
            >
              <div
                style={{
                  position:'absolute',
                  top:0,
                  right:0,
                  cursor:'pointer'
                }}
              >
                {
                  fullscreen
                    ?
                      <FullscreenExit
                        onClick={() => setFullscreen(false)}
                        sx={{
                          fontSize:{xs:36, sm:56}
                        }}
                      />
                    :
                      <Fullscreen
                        onClick={() => setFullscreen(true)}
                        sx={{
                          fontSize:{xs:36, sm:56}
                        }}
                      />
                }
                <Close
                  onClick={handleClose}
                  sx={{
                    fontSize:{xs:36, sm:56}
                  }}
                />

              </div>
              <div
                style={{
                  position:'absolute',
                  top:0,
                  left:0,
                  cursor:'pointer'
                }}
              >
              </div>
              <MainWrapper>
                <Grid
                  container
                  // sx={{
                  //   justifyContent:'space-between'
                  // }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      // p:2,
                      pt:isMobile ? 4 : 2
                    }}
                  >
                    Notes for {pageType} pages
                    <Button href="/notes">View All Notes</Button>
                  </Typography>
                  <Typography
                    sx={{
                      // p:2,
                      // pt:isMobile ? 4 : 2
                    }}
                  >
                    Notes will save automatically when closed. Made changes you don't want? <span onClick={() => setLogNotes(initLogNotes ? initLogNotes : logNotes)} style={{cursor:'pointer', color:theme.palette.brand.five}}>Click to reset </span> to the last saved notes.
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{

                  }}
                >
                  {
                    logNotes?.[pageTypeField]?.map((item, index) => (
                      // index === logNotes?.[pageTypeField][0]
                      //   ?
                      //     <Grid
                      //       container
                      //       key={item.id}
                      //     >
                      //       <Grid
                      //         xs
                      //       >
                      //         <TextField
                      //           multiline
                      //           maxRows={fullscreen ? 0 : 10}
                      //           fullWidth
                      //           placeholder={`Enter notes to share with the Opine dev team about this page`}
                      //           value={item.note}
                      //           onChange={(e) => handleNoteUpdate(item.id, e.target.value)}
                      //         />
                      //       </Grid>
                      //       <Grid
                      //         xs="auto"
                      //       >
                      //         <Button
                      //           disabled={!item.note.length}
                      //           onClick={() => handleDone(item.id)}
                      //         >
                      //           Done
                      //         </Button>
                      //       </Grid>
                      //     </Grid>
                      //   :
                          <Grid
                            container
                            key={item.id}
                          >
                            <Grid
                              xs
                            >
                              {
                                editId === item.id || (!editId && index === 0)
                                  ?
                                    <TextField
                                      multiline
                                      maxRows={fullscreen ? 0 : 10}
                                      fullWidth
                                      autoFocus
                                      placeholder={`Enter a new note about your experience on this page`}
                                      value={item.note}
                                      onChange={(e) => handleNoteUpdate(item.id, e.target.value)}
                                    />
                                  :
                                  <>
                                      <Typography
                                        sx={{
                                          fontStyle:'italic',
                                        }}
                                      >
                                        {item.note}
                                      </Typography>
                                      <Typography
                                      sx={{
                                        fontStyle:'italic',
                                      }}
                                    >
                                      {
                                        // Options for toLocaleDateString()
                                        // weekday: 'narrow' | 'short' | 'long' (e.g. 'Mon', 'Monday')
                                        // era: 'narrow' | 'short' | 'long' (e.g. 'AD', 'Anno Domini')
                                        // year: 'numeric' | '2-digit'
                                        // month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
                                        // day: 'numeric' | '2-digit'
                                        // hour: undefined | 'numeric' | '2-digit'
                                        // minute: undefined | 'numeric' | '2-digit'
                                        // second: undefined | 'numeric' | '2-digit'
                                        // timeZoneName: 'short' | 'long'

                                        // Options for toLocaleTimeString()
                                        // hour: 'numeric' | '2-digit'
                                        // minute: 'numeric' | '2-digit'
                                        // second: 'numeric' | '2-digit'
                                        // hour12: true | false
                                        // timeZoneName: 'short' | 'long'

                                        `${new Date(item?.updatedAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })} 
                                        at ${new Date(item?.updatedAt).toLocaleTimeString('en-US', { hour:'2-digit', minute:'2-digit' })}`
                                      }
                                    </Typography>
                                  </>
                            }
                            </Grid>
                            <Grid
                              xs="auto"
                            >
                              {
                                editId === item.id || (!editId && index === 0)
                                  ?
                                    <Button
                                      disabled={!item.note.length}
                                      // onClick={() => handleDone(index)}
                                      onClick={() => {
                                        if (index === 0) {
                                          handleDone(item.id);
                                        } else {
                                          setEditId(null);
                                        }

                                      }}
                                    >
                                      Done
                                    </Button>
                                  :
                                    <Edit
                                      onClick={() => setEditId(item.id)}
                                    />
                              }

                            </Grid>
                          </Grid>
                    ))
                  }
                </Grid>
              </MainWrapper>
            </Grid>
          </Drawer>
      </Box>



    </>
  )
}

export default LogNotes