import { Grid } from '@mui/material';

const LoadingScreen = () => {


  return (
    <Grid
      sx={{
        backgroundColor: 'green',
        minHeight: '100%'
      }}
    />
  );
};

export default LoadingScreen;
