import { useState, useEffect } from "react";

export const ParallaxScroll = () => {
  const [ offsetY, setOffsetY ] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  },[])

  return offsetY;
}

export const zeroFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const timeDifferenceObj = (previous) => {

  var msPerSecond = 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerWeek = msPerDay * 7;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var now = new Date();

  // This statement will be used if the date being compared is in the past
  if (now > previous) {
    var elapsed = now - previous;

    if (elapsed < msPerMinute) {
      return ' ';   
    }

    else {
        // return Math.round(elapsed/msPerDay); 
        return {
          seconds: Math.round(elapsed/1000),
          minutes: Math.round(elapsed/msPerMinute),
          hours: Math.round(elapsed/msPerHour),
          days: Math.round(elapsed/msPerDay),
          months: Math.round(elapsed/msPerMonth),
          years: Math.round(elapsed/msPerYear)
        }  
    }

  }

}

export const timeDifference = (previous) => {

  var msPerSecond = 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerWeek = msPerDay * 7;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var now = new Date();

  // This statement will be used if the date being compared is in the past
  if (now > previous) {
    var elapsed = now - previous;

    if (elapsed < msPerMinute) {
      return ' ';   
    }
    
    else if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + ' seconds ago';   
    }
  
    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }
  
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }
  
    else if (elapsed < msPerMonth) {
        return ' ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }
  
    else if (elapsed < msPerYear) {
        return ' ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }
  
    else {
        return ' ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }

  }

  // This statement will be used if the date being compared is in the future
  if (previous > now) {
    var elapsed = previous - now;

    if (elapsed < msPerMinute) {
      return ' ';   
    }
    
    else if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + ' seconds left';   
    }
  
    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes left';   
    }
  
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours left';   
    }
  
    else if (elapsed < msPerMonth) {
        return ' ' + Math.round(elapsed/msPerDay) + ' days left';   
    }
  
    else if (elapsed < msPerYear) {
        return ' ' + Math.round(elapsed/msPerMonth) + ' months left';   
    }
  
    else {
        return ' ' + Math.round(elapsed/msPerYear ) + ' years left';   
    }


  }


}

export const timeUntilDue = (dueDate, measurement) => {

  var msPerSecond = 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerWeek = msPerDay * 7;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var now = new Date();

  if (now > dueDate) {
    return 0;
  }

  var elapsed = dueDate - now;
  if (measurement === 'seconds') {
    return Math.round(elapsed/msPerSecond);
  }

  if (measurement === 'minutes') {
    return Math.round(elapsed/msPerMinute);
  }
  
  if (measurement === 'hours') {
    return Math.round(elapsed/msPerHour);
  }
  
  if (measurement === 'days') {
    return Math.round(elapsed/msPerDay);
  }
  
  if (measurement === 'weeks') {
    return Math.round(elapsed/msPerWeek);
  }

  if (measurement === 'months') {
    return Math.round(elapsed/msPerMonth);
  }

  if (measurement === 'years') {
    return Math.round(elapsed/msPerYear);
  }

  // if (elapsed < msPerMinute) {
  //   return ' ';   
  // }
  
  // else if (elapsed < msPerMinute) {
  //   return Math.round(elapsed/1000) // + ' seconds left';   
  // }

  // else if (elapsed < msPerHour) {
  //   return Math.round(elapsed/msPerMinute) // + ' minutes left';   
  // }

  // else if (elapsed < msPerDay ) {
  //   return Math.round(elapsed/msPerHour ) // + ' hours left';   
  // }

  // else if (elapsed < msPerWeek) {
  //   return ' ' + Math.round(elapsed/msPerDay) // + ' days left';   
  // }

  // else if (elapsed < msPerMonth) {
  //     return ' ' + Math.round(elapsed/msPerDay) // + ' weeks left';   
  // }

  // else if (elapsed < msPerYear) {
  //   return ' ' + Math.round(elapsed/msPerMonth) // + ' months left';   
  // }

  // else {
  //   return ' ' + Math.round(elapsed/msPerYear ) // + ' years left';   
  // }




}

export const justModified = (createdAt, seconds) => {

  const elapsedTime = seconds*1000
  const createdTime = createdAt?.toDate();

  const now = new Date();

  const elapsed = now - createdTime;

  if (elapsed < elapsedTime) {
    return true
  } else {
    return false
  }  

}

export const grabAttention = (time, param1, param2 ) => {

  let count = setInterval(funct, 5000, param1, param2);

  let set = param1;
  function funct (param1, param2) {
    if (set == param1) {
      set = param2
    } else {
      set = param1
    }
    // console.log("hello");
  }

  // return count

}

// Convert field name based on what content type it is
export const fieldName = (name, content, field) => {
  let fullFieldString = `${name}.${content?.type}${field}`
  let newField = eval(fullFieldString)
  return newField
} 

// Create doc name based on two ids
export const setLinkState = (teacher, student) => {
  const arr = [teacher, student];
  const newArr = arr.sort();
  const linkState = `${newArr[0]}-${newArr[1]}`
  return linkState;
}

// Copy a text string to the clipboard
export const copyString = (text) => {
  navigator.clipboard.writeText(text);
}

// Get the current user's display info for the current assignment
export const getDisplayItem = (displayNames, assignmentId) => { 
  const displayItem = displayNames.filter(x => x.assignmentId == assignmentId);

  return displayItem[0]
}

// Checks if two objects are equal (key order does not affect the outcome)
export const isObjEqual = (object1, object2) => {

    function isObject(object) {
      return object != null && typeof object === 'object';
    }

  if (!isObject(object1) || !isObject(object2)) {
    return false;
  }

  // function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        areObjects && !isObjEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;

}

// Randomize incoming array based on the Fisher Yates method
export const randomizeArray = (array) => {
const newArr = [...array];
for (let i = newArr.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  const temp = newArr[i];
  newArr[i] = newArr[j];
  newArr[j] = temp;
}
return(newArr);
}

// Gets a random string of integers
export const getRandomInts = (num) => {
  const randomIntArr = new Uint32Array(10);
  // console.log('randomIntArr', randomIntArr)

  const randomValue = crypto.getRandomValues(randomIntArr);
  // console.log('randomValue', randomValue)

  const randomInt = randomIntArr[0].toString();
  // console.log('randomInt', randomInt)

  const desiredInt = randomInt.slice(0, num);
  // console.log('desiredInt', desiredInt)

  return desiredInt
}

export const getMultipleRandom = (array, num) => {
  let remainingArr = [...array];
  // console.log('remainingArr', remainingArr);

  let newArr = [];
  // console.log('newArr', newArr);

  Array.from({length:num}).forEach(answer => {
    const item = remainingArr[Math.floor(Math.random()*remainingArr.length)];
    // console.log('item', item);

    newArr = [...newArr, ...remainingArr.splice(item, 1)];
    // console.log('newArr', newArr);

  })

  return {remaining: remainingArr, new: newArr}

}
