import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router';
// import { responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { guestRoutes, teacherRoutes } from './routes'
import { themeLight } from './theme';
import { useAuth } from './context/authContext';
import AlertsTemplate from './components/alerts/AlertsTemplate';
import LogNotes from './components/feedback/LogNotes';
// import Feedback from './components/ui/Feedback';

// const responsiveTheme = responsiveFontSizes(theme);

function App() {
  const authContext = useAuth();

  const guestContent = useRoutes(guestRoutes);
  const teacherContent = useRoutes(teacherRoutes);
    

  //Alerts
  useEffect(() => {
    if (authContext.alert) {
      handleNotification();
    }
  }, [authContext.alert])

  const [goTime, setGoTime] = useState(false);
  const [notifyContent, setNotifyContent] = useState();
  const handleNotification = () => {
    setNotifyContent(authContext.alert);
    setGoTime(true);
    setTimeout(() => {setGoTime(false)}, 5000);
  };
  const handleStop = () => {
    setGoTime(false);
  };

  return (
    <>
      <AlertsTemplate
        content={notifyContent}
        color="#9B30F9"
        goTime={goTime}
        setGoTime={setGoTime}
        handleNotification={handleNotification}
        handleStop={handleStop}
      />


        <ThemeProvider theme={themeLight}>
          {/* Notifications would go here */}
          {authContext.account
                ?
                  <>
                    {teacherContent}
                    <LogNotes />
                  </>
                : 
                  guestContent
          }
        </ThemeProvider>


    </>
  );
}

export default App;
